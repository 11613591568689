<template>
  <div class="container" v-if="!isLoading">
    <div class="card-list">
      <div class="card bind-steam-card" v-if="!player || !player.steam_account">
        <div class="card-content">
          <router-link :to="{ name: 'BindSteam' }"> <van-button block class="blue-btn" type="primary">绑定SteamID</van-button></router-link>
          <p>绑定SteamID后即可解锁个人游戏数据功能</p>
        </div>
      </div>

      <template v-else>
        <div class="card1"  :key="player.name">
          <div class="card-header">
            <div class="game-logo">
              <img src="/images/game-ranking/ranking1.png" alt="" />
            </div>
            <div class="game-name">S41赛季</div>
          </div>
          <div class="card-content">
            <div class="data-item">
              <div class="data-value">{{  gameData.score ? parseInt(gameData.score) : '-' }}</div>
              <div class="data-label">天梯分</div>
            </div>
<!--            <div class="data-item">-->
<!--              <div class="data-value">{{  gameData.average_ranking ? gameData.average_ranking.toFixed(3) : '-' }}</div>-->
<!--              <div class="data-label">单排平均排名</div>-->
<!--            </div>-->
            <div class="data-item">
              <div class="data-value">{{  gameData.response.length ?  gameData.response.length  : '-' }}</div>
              <div class="data-label">比赛场次</div>
            </div>
          </div>
        </div>
      </template>
      <div class="match-detail" >{{Text}}</div>
      <van-list v-model:loading="isLoading" >
        <div class="card2" v-for="item in list" :key="item.id">
          <div class="card-right">
            <div class="card-content">
              <div class="data-item" style="width: 30%">
                <div class="data-value">{{ timestampToDateFormat(item.create_at)}}</div>
              </div>
              <div class="data-item" style="width: 30%">
                <div class="data-value">{{ item.finish_roshan == "1" ? "胜利" : "失败" }}</div>
              </div>
              <div class="data-item" style="width: 40%">
                <div class="data-value">{{ item.score ? item.score : 0}}分</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>

    </div>

  </div>
</template>
<script>
export default {
  name: 'Lunhui',
}
</script>
<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import {playerT1Data } from '@/api/gameData'
import { Button} from 'vant'
import { formatDuration, timestampToDateFormat } from '@/utils/date'
const VanButton = Button
const store = useStore()

onMounted(() => {
  initData()
})

const isLoading = ref(true)
const player = ref({})
const gameData = ref([])
const list = ref([])
const Text = ref("历史对局")
const initData = () => {
  store.dispatch('player/getInfo').then(res => {
    player.value = res.data.player
    isLoading.value = false
  })
  getPlayerData()
}
const getScore = (item) => {
  if (item > 0) {
    item = '+' + item
  }
  return item
}
const getPlayerData = () => {
  playerT1Data().then(res => {
    gameData.value = res.data.gameData
    list.value = res.data.gameData.response
    if (res.data.gameData.length === 0) {
      Text.value = "暂无历史对局"
    }
  })
}
</script>

<style lang="scss" scoped>
.card-list {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.card1 {
  margin: 3px 5px;
  box-sizing: border-box;
  border-top: 1px solid var(--app-line-color);
  width: 99vw;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #2d2d35;
}
.card2 {
  margin: 0 5px;
  box-sizing: border-box;
  border-top: 1px solid var(--app-line-color);
  width: 99vw;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #2d2d35;
}

.card-header {
  padding-bottom: 10px;
  display: flex;
  border-radius: 10px 10px 0 0;
  align-items: center;
  border-bottom: 1px solid #424450;
}

/*.card-header:after {*/
/*    font-family: "iconfont";*/
/*    content: "\e666";*/
/*    font-weight: 600;*/
/*    color:#b0b5cb;*/
/*    margin-left: auto;*/
/*}*/

.game-logo {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid #fff;
}

.game-logo img {
  border-radius: 5px;
  width: 100%;
}

.game-name {
  margin-left: 10px;
  font-size: 15px;
  line-height: 100%;
  color: #fff;
  font-weight: 600;
}

.card-content {
  display: flex;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
}

.card-content .data-item {
  padding-top: 5px;
  text-align: center;
  width: 33.3%;
  position: relative;
}

.card-content .data-label {
  font-size: 12px;
  font-weight: 600;
  color: #7c8197;
}

.card-content .data-value {
  font-size: 15px;
  font-weight: 500;
  color: #d2d8e8;
}

.bind-steam-card {
  padding: 50px 0;
  position: relative;
}

.bind-steam-card::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('/images/common/tui6-logo.jpg') no-repeat center;
  background-size: cover;
  opacity: 0.3;
  filter: blur(3px);
}

.bind-steam-card .blue-btn {
  border-radius: 20px;
  width: 200px;
}
.match-detail{
  margin: 2vw;
  font-size: 4vw;
  color: white;
}
.bind-steam-card p {
  color: #b0b4c9;
  font-size: 12px;
  padding-top: 25px;
}
.match-item {
  padding: 10px 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ranking {
  flex-shrink: 0;
  width: 40px;
  color: #d2d8e8;
}
.card-content {
  //padding-top: 5px;
  display: flex;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
  align-items: center;
}
</style>
